<template>
    <div
        class="sum"
    >
        <VueSlider
            v-model="loanSum"
            :dotSize="12"
            :min="config.min"
            :max="config.max"
            :interval="config.step"
        />
        <div
            v-if="showRangeInfo"
            class="sum__range-info"
        >
            <span
                v-for="item in showRangeInfo"
                :key="item"
            >
                {{ item === 1 ? config.min : ((config.max / (showRangeInfo - 1)) * (item - 1) || 0) | price }} ₽
            </span>
        </div>
    </div>
</template>

<script>
import './sum.scss'

import price from '@/helpers/string/price'
import { mapGetters } from 'vuex'

export default {
    name: 'Calculator',
    props: {
        model: {
            type: Number
        },
        showRangeInfo: {
            type: Number,
            default: 0
        }
    },
    model: {
        prop: 'model',
        event: 'change',
    },
    data() {
        return {
            config: {
                min: 1000,
                max: 100000,
                step: 1000
            }
        }
    },
    created() {
        this.loanSum = this.calculator.amount
    },
    computed: {
        ...mapGetters({
            calculator: 'application/calculator'
        }),
        loanSum: {
            set(amount) {
                this.$emit('change', amount)
                this.$store.commit('application/updateCalculator', { amount })
            },
            get() {
                return this.model
            }
        }
    },
    components: {
        VueSlider: () => import('vue-slider-component'),
    },
    filters: {
        price
    }
};
</script>
